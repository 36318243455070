import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
// import { SettingOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BusinessIcon from '@material-ui/icons/Business';
// import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { MenuProps } from 'antd';
import { Menu } from 'antd';
import { navigate } from 'gatsby';

import { initialize } from '../actions/userActions';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../components/Loading';
import LoadingLogo from '../components/LoadingLogo';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import { loadingEndAction } from '../reducers/loadingReducer';
import debug from '../utils/debug';

// import '../styles/ant-menu-style.scss';

const isBrowser = typeof window !== 'undefined';

type MenuItem = Required<MenuProps>['items'][number];

const styles = (): StyleRules =>
  createStyles({
    noAdmin: {
      height: 1000,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    noAdminItem: {
      margin: '1%',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    nav: {
      width: '210px',
      padding: '20px',
      textAlign: 'left',
      background: '#9575cd',
    },
    content: {
      margin: '0 auto',
      width: '1350px',
    },
  });

const AdminLayout = ({
  classes,
  children,
  verificationHide = false,
}: Props): React.ReactElement => {
  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem =>
    ({
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem);

  const items: MenuProps['items'] = [
    getItem('企业管理', 'adminCompany', <BusinessIcon />, [
      getItem(
        '企业项目管理',
        'g1',
        null,
        [
          getItem('发布项目', 'companyPublishProject'),
          getItem('项目列表', 'companyProjectList'),
          getItem('申请列表', 'companyBiddingList'),
          getItem('项目结算', 'companyProjectSettlement'),
          getItem('项目评价', 'companyProjectEvaluate'),
        ],
        'group'
      ),
      getItem(
        '企业用户',
        'g2',
        null,
        [getItem('企业列表', 'company'), getItem('企业认证', 'companyCertification')],
        'group'
      ),
    ]),

    getItem('顾问管理', 'adminConsultant', <AccountBoxIcon />, [
      getItem(
        '顾问需求',
        'sub3',
        null,
        [
          // getItem('发布项目', 'consultantPublishProject'),
          // getItem('项目列表', 'consultantProjectList'),
          getItem('申请列表', 'consultantBiddingList'),
          getItem('项目结算', 'consultantProjectSettlement'),
          getItem('项目评价', 'consultantProjectEvaluate'),
        ],
        'group'
      ),
      getItem(
        '顾问用户',
        'sub4',
        null,
        [getItem('顾问列表', 'consultant'), getItem('顾问认证', 'consultantCertification')],
        'group'
      ),
    ]),
  ];

  debug('Loading admin layout');
  const { id: uid, userType } = useSelector((appState: AppState) => appState.user);
  const { id: adminId, loaded: adminLoaded } = useSelector((appState: AppState) => appState.admin);
  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const { isVisible, severity, message } = useSelector((state: AppState) => state.notification);
  const dispatch = useDispatch();

  const [selectKeys, setSelectKeys] = useState('1');
  const [openKeys, setOpenKeys] = useState('sub1');

  const onClick: MenuProps['onClick'] = e => {
    const openKey = e.keyPath[1].includes('admin') ? 'admin' : '';
    // if (openKey) {
    e.keyPath[0] ? navigate(`/${openKey}/${e.keyPath[0]}`) : navigate(`/${e.keyPath[1]}/`);
    // }
  };

  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys[openKeys.length - 1]);
  };
  useEffect(() => {
    dispatch(initialize(userType));
    dispatch(loadingEndAction());
  }, []);

  const currentPath = isBrowser ? window.location.pathname : '';
  useEffect(() => {
    const parts = currentPath.split('/');
    const result = parts.slice(1);
    const openKey = String(result[1]).includes('company')
      ? 'adminCompany'
      : String(result[1])
          .toString()
          .includes('consultant')
      ? 'adminConsultant'
      : '';
    setOpenKeys(openKey);
    setSelectKeys(result[1]);
  }, [currentPath]);

  const NoAdmin = (
    <Grid container direction="column" className={classes.noAdmin}>
      <Grid item className={classes.noAdminItem}>
        <Typography style={{ fontSize: 25 }}>你不是管理员</Typography>
      </Grid>
      <Grid item className={classes.noAdminItem}>
        <Button
          onClick={() => {
            navigate('/');
          }}
          variant="contained"
          color="primary"
          style={{
            width: '20%',
          }}
        >
          返回首页
        </Button>
      </Grid>
    </Grid>
  );

  if (uid && !adminLoaded && !adminId && !isLoading) {
    return <>{NoAdmin}</>;
  }

  if (uid && children) {
    return (
      <>
        <Helmet>
          <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
        </Helmet>
        <Header />
        <div className={classes.container}>
          {!verificationHide && (
            <Menu
              theme="light"
              onClick={onClick}
              style={{ width: 256 }}
              selectedKeys={[selectKeys]}
              openKeys={[openKeys]}
              onOpenChange={onOpenChange}
              mode="inline"
              items={items}
            />
          )}
          <div className={classes.content}>{children}</div>
        </div>

        <Footer />
        {isLoading && <Loading />}
        {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
      </>
    );
  }

  return <LoadingLogo />;
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
  verificationHide?: boolean;
}

export default withStyles(styles)(AdminLayout);
